.app {
  height: calc(var(--vh, 1vh) * 100);

  max-width: 800px;
  width: 95%;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header a {
  color: #000;
  text-decoration: none;
}

.header {
  font-size: larger;
  border-bottom: 1px solid black;
  text-align: center;
  flex: 0 0 0;
  margin: 10px 0;

  display: flex;
  justify-content: space-between;
}

.header div {
  align-self: center;
}

.login-user {
  cursor: pointer;
}

.button {
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid blue;
  background-color: #00a;
  color: white;
  padding: 10px;
  margin: 10px 0;
  width: 150px;
  text-align: center;
  user-select: none;
}

.button.left {
  margin-left: 0;
}

.big.button {
  font-size: xx-large;
}

.button.enabled {
  border: 1px solid green;
  background-color: #0a0;
  cursor: pointer;
}

.button.disabled {
  border: 1px solid grey;
  background-color: #aaa;
  cursor: default;
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

.debug {
  color: silver;
  font-size: xx-small;
}

.build-time {
  z-index: 0;
  text-align: right;
}

.user-icon {
  border: 1px solid green;
  background-color: #0a0;
  border-radius: 50%;
  width: 2rem;
  line-height: 2rem;
  font: bold;
}

.loader.hide .spinner {
  display: none;
}

.loader.loading {
  display: block;
  pointer-events: none;
  opacity: 0.4;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

@media screen and (min-width: 500px) {
  .button {
    width: 200px;
    font-size: medium;
  }
}
